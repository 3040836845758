<template>
<div>
    <div @click="onCickRelay()" :class="{blink : isBlink == true, green : isGreen == true}" id="project-name">
        <span>DMX Lighting 2.0 Demo</span>
        <img v-if="failure" src="../assets/images/warning.svg" />

    </div>
    <div id="light-range" class="pana-range">
        <span><img width="10" src="../assets/images/sun-small.svg" /></span>
        <input @change="onLightRangeChanged()" v-model="lightrange" type="range" class="range purple form-range" min="0" max="255" step="1" id="lightrange1">
        <span><img width="10" src="../assets/images/sun-large.svg" /></span>

    </div>
    <div id="speed-range" class="pana-range">
        <span><img width="10" src="../assets/images/ic_speed_min@3x.png" /></span>
        <input @change="onSpeedRangeChanged()" v-model="speedrange" type="range" class="range purple form-range" min="1" max="1000" step="1" id="speedrange">
        <span><img width="10" src="../assets/images/ic_speed_max@3x.png" /></span>

    </div>
    <div class="home-color">
        <div class="row">
            <div @click="onClickItem(item)" v-for="(item,index) in items" :key="index" class="col-xs-4">
                <div :class="{active : item.id == expected_cue.id}" class="p-1 border  box">{{item.name}}</div>
            </div>

        </div>
    </div>

</div>
</template>

<script>
import {
    defineComponent
} from "vue";
import appService from "../services/app";

export default defineComponent({
    name: "Login",
    mounted() {
        this.getCueList();
        this.getAppStatus();
        window.setInterval(() => {
            if (this.status.failure_detection == true || this.status.expected_relay != this.status.relay) {
                this.getAppStatus();
            }
        }, 5000)
    },
    data() {
        return {
            items: [],
            expected_cue: {},
            failure: true,
            isBlink: false,
            isGreen: false,
            status: {},
            lightrange: 0,
            speedrange: 0,
            relay: 'on'
        };
    },
    methods: {
        onLightRangeChanged() {
            this.updateAppStatus();
        },
        onSpeedRangeChanged() {
            this.updateAppStatus();
        },
        onCickRelay(){
            // this.updateAppStatus();
            // this.relay = !this.relay;
           if (this.expected_relay == 'on') {
             this.expected_relay = 'off'
           } else {
             this.expected_relay = 'on'
           }

           this.updateAppSetting();
        },
        async getCueList() {
            let result = await appService.getCueList();
            this.items = result;
        },
        async getAppStatus() {
            let result = await appService.getAppStatus();
            this.status = result;

            this.expected_cue = result.expected_cue;

            if (result.failure_detection && result.failure) {
                this.failure = true
            } else {
                this.failure = false;
            }

            if (result.relay != result.expected_relay) {
                if (this.isGreen == true){
                  this.isGreen = false;
                }
                this.isBlink = true;
            } else {
                this.isBlink = false;
                if (result.expected_relay === 'on') {
                    this.isGreen = true;
                } else {
                    this.isGreen = false;
                }
            }

            this.lightrange = result.expected_intensity;
            this.speedrange = result.expected_speed;
            this.relay = result.relay

            console.log(result);
        },
        onClickItem(item) {
            this.expected_cue = item;
            this.updateAppStatus();
        },
        async updateAppSetting() {
            let body = {
                "relay": this.expected_relay 
            }
            let result = await appService.updateSetting(body);
            console.log(result);
            if (result) {
                // alert('Save successful!');
            }
        },
        async updateAppStatus() {
            let body = {
                "cue_id": parseInt(this.expected_cue.id),
                "intensity": parseInt(this.lightrange),
                "speed": parseInt(this.speedrange),
                "mode": "Mono"
            }
            let result = await appService.updateAppStatus(body);

            if (result) {
                // alert('Save successful!');
            }
        }
    }
});
</script>

<style lang="scss" module></style>
